.section{
    width: 100%;
    min-height: 100vh;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    overflow: hidden;
    background: rgb(14, 13, 13);
    font-family: SourceHanSansSc;
    background-image: url("../assets/bg10.png");
    // background-size: 100% 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.content{
    margin: 142px auto;
    display: flex;
    width: 1440px;
    height: 400px;
    flex-direction: column;
    align-items: flex-start;
}
.title{
    font-size: 60px;
    font-weight: 700;
    color: #fff;
}
.lis{
    text-align: left;
}