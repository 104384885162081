.section{
    width: 100%;
    height: 100%;
    margin: 77px auto 0;
    color: #fff;
    position: relative;
    font-family: SourceHanSansSc;
}
.imgBox{
    width: 1163.206px;
    height: 850.183px;
    text-align: left;
    margin-left: 120px;
}
.eyeBox{
    position: absolute;
    right:-200px;
    top: -10px;
    width: 100%;
    height: 100%;
    text-align: right;
}
.imgFont{
    width: 770px;
    height: 702px;
    object-fit: fill;
}
.imgEye{
    width: 1417px;
    height: 1080px;
    object-fit: fill;
}